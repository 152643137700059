import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header className="bg-custom-blue text-white body-font">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a className="flex title-font font-medium items-center text-white mb-4 md:mb-0"
                    href='/'>
                    <img
                        className="w-20 h-20 rounded-lg"
                        src="./assets/icons/apple-touch-icon.png"
                        alt="App Icon"
                    />
                    <span className="ml-3 text-xl">Personalized Learning USabana App</span>
                </a>
                <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
                    <Link to="/login" className="mr-5 hover:text-gray-300">Acceder</Link>
                    <Link to="/register" className="mr-5 hover:text-gray-300">Registrarse</Link>
                    <Link to="/reset-password" className="mr-5 hover:text-gray-300">Cambiar Contraseña</Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;