import React from 'react';

const Loading = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        <div className='spinner-border animate-spin inline-block border-0' role='status'>
          <img
            className='w-16 h-16 rounded-full'
            alt=''
            src='/assets/images/logo_alfa_rot.png'  >
            </img>
        </div>
        <h2 className='mt-4 text-xl font-bold'>Cargando...</h2>
      </div>
    </div>
  );
};

export default Loading;