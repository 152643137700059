import React from 'react';

const WelcomeStudent = ({ currentUser, handleNavigateToCHAEA, handleNavigateToScores, handleSignOut, hasAnswers }) => {
  return (
    <div className='flex flex-col items-center w-full top-10 justify-center text-2xl font-bold pt-10 text-center'>
      <h1>Bienvenido(a) {currentUser.displayName ? currentUser.displayName : currentUser.email} a la experiencia PLUSabana.</h1>
      <h2 className='mt-4'>¿Qué deseas hacer hoy?</h2>

      <button
        onClick={handleNavigateToCHAEA}
        className='text-xl px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mt-6 mb-1'
      >
        CHAEA
      </button>

      {hasAnswers && (
        <button
          onClick={() => handleNavigateToScores(currentUser)}
          className='text-xl px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mt-6 mb-1'
        >
          Resultados
        </button>
      )}

      <button
        onClick={handleSignOut}
        className='text-xl mt-10 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700'
      >
        Cerrar sesión
      </button>
    </div>
  );
};

export default WelcomeStudent;