import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Loading from './Loading';

const ScoringAnswers = () => {
  const [scores, setScores] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAndScoreAnswers = async () => {
      try {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;

        if (!user) {
          throw new Error('No authenticated user found');

        }

        const docRef = doc(db, 'users', user.uid, 'answers', 'latest');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const answers = docSnap.data().answers;
          const calculatedScores = calculateScores(answers);
          setScores(calculatedScores);
        } else {
          throw new Error('No answers found for this user');
        }
      } catch (err) {
        setError(err.message);
        console.error(err);
        navigate('/login');
      }
    };

    fetchAndScoreAnswers();
  }, [navigate]);

  const calculateScores = (answers) => {
    const activo = [3, 5, 7, 9, 13, 20, 26, 27, 35, 37, 41, 43, 46, 48, 51, 61, 67, 74, 75, 77];
    const reflexivo = [10, 16, 18, 19, 28, 31, 32, 34, 36, 39, 42, 44, 49, 55, 58, 63, 65, 69, 70, 79];
    const teorico = [2, 4, 6, 11, 15, 17, 21, 23, 25, 29, 33, 45, 50, 54, 60, 64, 66, 71, 78, 80];
    const pragmatico = [1, 8, 12, 14, 22, 24, 30, 38, 40, 47, 52, 53, 56, 57, 59, 62, 68, 72, 73, 76];

    const intervalos_activo = [0, 6, 8, 10, 13, 20];
    const intervalos_reflexivo = [0, 11, 14, 17, 18, 20];
    const intervalos_teorico = [0, 7, 10, 14, 15, 20];
    const intervalos_pragmatico = [0, 8, 10, 14, 16, 20];

    const rawScores = {
      activo: 0,
      reflexivo: 0,
      teorico: 0,
      pragmatico: 0
    };

    answers.forEach((answer, index) => {
      if (answer === 'Sí') {
        if (activo.includes(index + 1)) rawScores.activo++;
        if (reflexivo.includes(index + 1)) rawScores.reflexivo++;
        if (teorico.includes(index + 1)) rawScores.teorico++;
        if (pragmatico.includes(index + 1)) rawScores.pragmatico++;
      }
    });

    const normalizeScore = (score, intervals) => {
      for (let i = 1; i < intervals.length; i++) {
        if (score <= intervals[i]) {
          return i;
        }
      }
      return 5; 
    };
    return {
      activo: [normalizeScore(rawScores.activo, intervalos_activo), rawScores.activo],
      reflexivo: [normalizeScore(rawScores.reflexivo, intervalos_reflexivo), rawScores.reflexivo],
      teórico: [normalizeScore(rawScores.teorico, intervalos_teorico), rawScores.teorico],
      pragmático: [normalizeScore(rawScores.pragmatico, intervalos_pragmatico), rawScores.pragmatico]
    };
  };

  if (error) {
    console.error(error);
    navigate('/login');}
    
  if (!scores) {
  return <Loading />;
}
const handleExit = () => {
  navigate('/home');
};
  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto">
      <h2 className="text-2xl mb-4 text-center text-gray-800">
        De acuerdo con tus respuestas, esta es tu <strong>combinación de estilos de aprendizaje</strong> en escala de 1 a 5:
      </h2>
      <div className="space-y-4 mb-6 w-1/2 mx-auto">
        {Object.entries(scores).map(([style, score]) => (
          <div key={style} className="grid grid-cols-3 gap-x-4 items-center text-center">
            <span className="text-lg font-medium text-gray-700 capitalize col-span-1">
              {style}:
            </span>
            <span className="text-lg font-semibold text-blue-600 col-span-1">
              {score[0]}
            </span>
            <span className="text-sm text-gray-500 col-span-1">
              *{score[1]} preguntas afirmativas*
            </span>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <button
          onClick={handleExit}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
        >
          Regresar
        </button>
      </div>
    </div>

  );
};

export default ScoringAnswers;