import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore, doc, setDoc, collection, getDocs, getDoc, updateDoc } from "firebase/firestore";

const db = getFirestore();

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // add user to firestore
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      isValidated: false,
      isTeacher: false,
      email: user.email,
      displayName: user.displayName || null,
      photoURL: user.photoURL || null,
      createdAt: new Date()
    });

    return userCredential;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("El correo electrónico ya está en uso.");
    } else if (error.code === 'auth/weak-password') {
      throw new Error("La contraseña debe tener al menos 6 caracteres.");
    } else {
      throw new Error("Error al crear el usuario: " + error.message);
    }
  }
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
      console.error("Usuario o contraseña incorrectos.");
      throw new Error("Usuario o contraseña incorrectos.");
    } else {
      console.error("Error al iniciar sesión:", error);
      throw error;
    }
  }
};

// Iniciar sesión con Google y agregar el usuario a Firestore si no existe
export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Verificar si el documento del usuario ya existe
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // Si el documento no existe, crear el documento con los datos del usuario
      await setDoc(userRef, {
        isValidated: false,
        isTeacher: false,
        email: user.email,
        displayName: user.displayName || null,
        photoURL: user.photoURL || null,
        createdAt: new Date()
      });
    }

    return result;
  } catch (error) {
    console.error("Error al iniciar sesión con Google:", error);
    throw error;
  }
};


export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email);
  return "Si existe una cuenta asociada a este correo, se ha enviado un mensaje.";
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};

// Obtener los datos del usuario desde Firestore
export const getUserData = async (uid) => {
  const userRef = doc(db, 'users', uid);
  const snapshot = await getDoc(userRef);
  if (snapshot.exists()) {
    return snapshot.data();
  } else {
    throw new Error("No se encontraron datos del usuario.");
  }
};


// Validar el correo electrónico del profesor en Firestore
export const validateTeacherEmail = async (email) => {
  const groupsCollection = collection(db, 'groups');
  const snapshot = await getDocs(groupsCollection);
  let isValid = false;

  snapshot.forEach((doc) => {
    const data = doc.data();
    if (data.teacherEmail === email) {
      isValid = true;
    }
  });

  return isValid;
};

export const updateUserValidationAndRole = async (uid, isValidated, isTeacher) => {
  const userRef = doc(db, 'users', uid);
  await updateDoc(userRef, { isValidated, isTeacher });
};

// Actualizar el estado de isValidated del usuario en Firestore
export const updateUserValidation = async (uid, isValidated) => {
  const userRef = doc(db, 'users', uid);
  await updateDoc(userRef, { isValidated });
};

export const validateAndUpdateTeacher = async (uid, email) => {
  const isValid = await validateTeacherEmail(email);
  const userRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userRef);

  if (isValid) {
    if (userDoc.exists() && userDoc.data().email === email) {
      // Verificar y agregar el campo isTeacher si no existe
      if (userDoc.data().isTeacher === undefined) {
        await updateDoc(userRef, { isTeacher: true });
      }
      await updateUserValidationAndRole(uid, true, true);
    } else {
      // Verificar y agregar el campo isTeacher si no existe
      if (userDoc.data().isTeacher === undefined) {
        await updateDoc(userRef, { isTeacher: false });
      }
      await updateUserValidation(uid, true);
    }

    // Refrescar la página
    window.location.reload();
  }
  return isValid;
};

export { db };