import React from 'react';

const WelcomeProfesor = ({ currentUser, handleNavigateToLearningUnits, handleNavigateToCrearGrupo, handleSignOut }) => {
  return (
    <div className='flex flex-col items-center w-full top-10 justify-center text-2xl font-bold pt-10 text-center'>
      <h1>Bienvenido(a) {currentUser.displayName ? currentUser.displayName : currentUser.email} a la experiencia PLUSabana.</h1>
      <h2 className='mt-4'>¿Qué deseas hacer hoy?</h2>

      <button
        onClick={handleNavigateToCrearGrupo}
        className='text-xl px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 mt-6 mb-1'
      >
        Crear Grupo
      </button>
      
      <button
        onClick={handleNavigateToLearningUnits}
        className='text-xl px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mt-6 mb-1'
      >
        Unidades de Aprendizaje
      </button>

      <button
        onClick={handleSignOut}
        className='text-xl mt-10 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700'
      >
        Cerrar sesión
      </button>
    </div>
  );
};

export default WelcomeProfesor;