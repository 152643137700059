import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData, doSignOut } from '../../firebase/auth'; // Asegúrate de ajustar la ruta de importación
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Loading from './Loading';
import EmailVerification from './EmailVerification';
import WelcomeStudent from './WelcomeStudent';
import WelcomeProfesor from './WelcomeProfesor';

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [error, setError] = useState('');
  const [teacherEmail, setTeacherEmail] = useState('');
  const [hasAnswers, setHasAnswers] = useState(false); // Nuevo estado para verificar si tiene respuestas
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    if (!currentUser) {
      console.log('No current user');
      setLoading(false); // Dejar de cargar si no hay usuario actual
      return;
    }

    // Simular la obtención de datos del usuario
    const fetchUserData = async () => {
      try {
        const userData = await getUserData(currentUser.uid);
        setIsValidated(userData.isValidated);
        setIsTeacher(userData.isTeacher !== undefined ? userData.isTeacher : ''); // Asegúrate de que isTeacher tenga un valor definido
        setTeacherEmail(userData.teacherEmail);

        // Verificar si el usuario tiene respuestas
        const docRef = doc(db, 'users', currentUser.uid, 'answers', 'latest');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setHasAnswers(true);
        }

      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Error fetching user data');
      } finally {
        setLoading(false); // Dejar de cargar después de obtener los datos
      }
    };

    fetchUserData();
  }, [currentUser, db]);

  const handleNavigateToCHAEA = () => {
    navigate('/questioncomponent');
  };

  const handleNavigateToLearningUnits = () => {
    navigate('/learning-units');
  };

  const handleNavigateToCrearGrupo = () => {
    navigate('/crear-grupo');
  };

  const handleNavigateToScores = () => {
    const userData = {
      uid: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      isValidated: isValidated,
      isTeacher: isTeacher,
    };
    navigate('/scoring-answers', { state: { currentUser: userData } });
  };

  const handleSignOut = () => {
    console.log('Signing out');
    doSignOut().then(() => {
      console.log('Signed out, navigating to login');
      navigate('/login');
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (!isValidated || (isTeacher === '')) {
    return (
      <EmailVerification
        teacherEmail={teacherEmail}
        setTeacherEmail={setTeacherEmail}
        setIsValidated={setIsValidated}
        setError={setError}
        currentUser={currentUser}
        handleSignOut={handleSignOut}
        error={error}
      />
    );
  }
  if (isTeacher) {
      return (
        <WelcomeProfesor
          currentUser={currentUser}
          handleSignOut={handleSignOut}
          handleNavigateToLearningUnits={handleNavigateToLearningUnits}
          handleNavigateToCrearGrupo={handleNavigateToCrearGrupo}
        />
      );
    
  }

  return (
    <WelcomeStudent
      currentUser={currentUser}
      handleNavigateToCHAEA={handleNavigateToCHAEA}
      handleNavigateToScores={handleNavigateToScores}
      handleSignOut={handleSignOut}
      hasAnswers={hasAnswers} // Pasar hasAnswers como prop
    />
  );
};

export default Home;