import React, { useState, useEffect } from 'react';
import { addDoc, collection, getDocs, query, where, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase/auth';
import { getAuth } from 'firebase/auth';

const LearningUnitsForm = () => {
  const initialFormData = {
    alcanceIncluido: '',
    alcanceExcluido: '',
    competenciasActitudinales: '',
    validacionActitudinal: '',
    competenciasCognitivas: '',
    validacionCognitiva: '',
    competenciasPerformativas: '',
    validacionPerformativa: '',
    horas: '',
    grupo: '', // Cambiado a una cadena para un solo grupo
  };

  const [formData, setFormData] = useState(initialFormData);
  const [showInfo, setShowInfo] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [gruposOptions, setGruposOptions] = useState([]); // Estado para las opciones de grupos
  const fibonacciSequence = [0, 1, 2, 3, 5, 8, 13];

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const userEmail = user.email;
          const q = query(collection(db, 'groups'), where('teacherEmail', '==', userEmail));
          const querySnapshot = await getDocs(q);
          const gruposList = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().groupName })); // Ajusta según la estructura de tus documentos
          setGruposOptions(gruposList);
        }
      } catch (error) {
        console.error('Error al obtener los grupos:', error);
      }
    };

    fetchGrupos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Crear la unidad de aprendizaje
      const docRef = await addDoc(collection(db, 'learningUnits'), formData);
      const learningUnitId = docRef.id;

      // Actualizar el grupo seleccionado para agregar la referencia a la unidad de aprendizaje
      const grupoRef = doc(db, 'groups', formData.grupo);
      await updateDoc(grupoRef, {
        learningUnits: arrayUnion(learningUnitId)
      });

      setFormSubmitted(true);
      setFormData(initialFormData);
      setTimeout(() => setFormSubmitted(false), 3000);
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  const toggleInfo = (field) => setShowInfo({ ...showInfo, [field]: !showInfo[field] });

  const infoMessages = {
    alcanceIncluido: 'Delimitar tan específicamente como sea posible el alcance nuevo conocimiento que se espera que el estudiante tenga, con una granularidad suficiente para que sea trabajable. <a href="https://en.wikipedia.org/wiki/SMART_criteria" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:underline">SMART goals</a>.',
    alcanceExcluido: 'Información adicional sobre el alcance excluido.',
    competenciasActitudinales: 'Descripción de las competencias actitudinales esperadas.',
    validacionActitudinal: 'Métodos de validación para las competencias actitudinales.',
    competenciasCognitivas: 'Descripción de las competencias cognitivas esperadas.',
    validacionCognitiva: 'Métodos de validación para las competencias cognitivas.',
    competenciasPerformativas: 'Descripción de las competencias performativas esperadas.',
    validacionPerformativa: 'Métodos de validación para las competencias performativas.',
    dificultadHoras: 'Seleccione la cantidad de horas estimadas para completar esta unidad de aprendizaje, basada en la secuencia de Fibonacci.',
    grupo: 'Seleccione el grupo al que pertenece esta unidad de aprendizaje.',
  };

  return (
    <div className="w-full max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 mt-10 py-10">
      <h2 className="text-2xl font-bold mb-4">Formulario de Unidades de Aprendizaje</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-8">
          <div className="flex items-center mb-2">
            <label className="block text-gray-700 ml-1">Grupo</label>
            <button
              type="button"
              onClick={() => toggleInfo('grupo')}
              className="ml-2 text-blue-500 hover:text-blue-700"
            >
              ?
            </button>
          </div>
          <div className="relative">
            {showInfo.grupo && (
              <div className="absolute bg-white p-2 rounded shadow-md mt-2 w-full">
                <div>{infoMessages.grupo}</div>
              </div>
            )}
            <select
              name="grupo"
              value={formData.grupo}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg bg-white"
              required
            >
              <option value="" disabled>Seleccione un grupo</option>
              {gruposOptions.map((grupo) => (
                <option key={grupo.id} value={grupo.id}>
                  {grupo.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {['alcanceIncluido', 'alcanceExcluido'].map((field, index) => (
          <div key={index} className="mb-4 relative">
            <div className="flex items-center">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1">
                {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
              </label>
              <button type="button" onClick={() => toggleInfo(field)} className="ml-2 text-blue-500 hover:text-blue-700">?</button>
            </div>
            {showInfo[field] && (
              <div className="absolute bg-white border border-gray-300 p-4 rounded-lg shadow-lg mt-2 z-50">
                <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: infoMessages[field] }}></p>
              </div>
            )}
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg mt-2"
              required
            />
          </div>
        ))}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          {['competenciasActitudinales', 'validacionActitudinal', 'competenciasCognitivas', 'validacionCognitiva', 'competenciasPerformativas', 'validacionPerformativa'].map((field, index) => (
            <div key={index} className="relative">
              <div className="flex items-center">
                {field.includes('validacion') && <span className="text-red-500">*</span>}
                <label className="block text-gray-700 ml-1">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim().replace('validacion', 'validación')}
                </label>
                <button type="button" onClick={() => toggleInfo(field)} className="ml-2 text-blue-500 hover:text-blue-700">?</button>
              </div>
              {showInfo[field] && (
                <div className="absolute bg-white border border-gray-300 p-4 rounded-lg shadow-lg mt-2 z-50">
                  <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: infoMessages[field] }}></p>
                </div>
              )}
              <input
                type="text"
                name={field}
                value={formData[field]}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-lg mt-2"
                required={field.includes('validacion')}
              />
            </div>
          ))}
        </div>
        <div className="mb-8">
          <label className="block text-gray-700">Dificultad en horas</label>
          <div className="gap-4 mt-2">
            <div>
              <div className="flex items-center mb-2">
                <label className="block text-gray-700 ml-1">Horas</label>
                <button
                  type="button"
                  onClick={() => toggleInfo('dificultadHoras')}
                  className="ml-2 text-blue-500 hover:text-blue-700"
                >
                  ?
                </button>
              </div>
              <div className="relative">
                {showInfo.dificultadHoras && (
                  <div className="absolute bg-white p-2 rounded shadow-md mt-2 w-full">
                    <div>{infoMessages.dificultadHoras}</div>
                  </div>
                )}
                <select
                  name="horas"
                  value={formData.horas}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-lg bg-white"
                >
                  {fibonacciSequence.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        {formSubmitted && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 border border-green-300 rounded-lg">
            Formulario enviado
          </div>
        )}
        <button type="submit" className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">Enviar</button>
      </form>
    </div>
  );
};

export default LearningUnitsForm;